import BasicTable from '@sportnet/ui/lib/BasicTable';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/lib/ContextBar';
import Filters from '@sportnet/ui/lib/Filter';
import ScrollLayout from '@sportnet/ui/lib/Layouts/ScrollLayout';
import { Loader } from '@sportnet/ui/lib/Loader';
import Paginator from '@sportnet/ui/lib/Paginator';
import AppContext from '@sportnet/ui/lib/TheLayout/AppContext';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import format from 'date-fns/format';
import withQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { commit, CommitError, getListTotal, isCommiting } from 'redux-list';
import { withTheme } from 'styled-components';
import api, { CodelistItem, FormInstance } from '../../../api';
import LoaderWrapper from '../../../components/LoaderWrapper';
import Tabber from '../../../components/Tabber';
import config from '../../../config';
import { CustomThunkDispatch } from '../../../configureStore';
import { initializeOrSetListParams } from '../../../containers/App/actions';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import { loadFormInstances } from '../../../containers/FormInstances/actions';
import { formInstancesSelector } from '../../../containers/FormInstances/selectors';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { getInstanceStatusLabel } from '../../FormInstance';

const LIST_NAME = 'FORM_INSTANCES_LIST';

const mapStateToProps = (state: RootState) => ({
  instances: formInstancesSelector(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  isListCommiting: isCommiting(LIST_NAME)(state),
  appSpace: activeAppspaceIdSelector(state),
});

type Props = RouteComponentProps<{ appSpace: string; formId: string }> &
  QueryHocInterface & { dispatch: CustomThunkDispatch } & ReturnType<
    typeof mapStateToProps
  > &
  Theme;

const FormInstances: React.FC<Props> = ({
  appSpace,
  dispatch,
  instances,
  isListCommiting,
  match: {
    params: { formId },
  },
  query,
  serializedQuery,
  setParameter,
  theme,
  total,
  history: { push },
}) => {
  const [statusesCodeList, setStatusesCodelist] = React.useState<
    CodelistItem[]
  >([]);
  React.useEffect(() => {
    dispatch(
      initializeOrSetListParams.action({
        listName: LIST_NAME,
        params: { ...query, formId },
      }),
    );
    api.getFormInstanceStatusCodelist(appSpace).then(({ codelist }) => {
      setStatusesCodelist(codelist.map(i => ({ ...i, key: i.value })));
    });
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(
              loadFormInstances.action({
                ...query,
                formId,
                limit: config.DEFAULT_LIST_LIMIT,
              }),
            );
            return {
              total: res.total,
              results: res.instances.map(a => a._id),
              nextOffset: null,
            };
          } catch (e) {
            if (e.details && e.details.name === 'NO_GROUPS_ASSIGNED') {
              alert(__('Nie ste pridelený do žiadnej kontrolnej skupiny.'));
            }
            throw new CommitError(e);
          }
        },
      }),
    );
  }, [serializedQuery, dispatch, formId, query, appSpace]);

  const renderRow = (i: FormInstance) => [
    i.finishDT
      ? format(new Date(i.finishDT || ''), 'DD.MM.YYYY o HH:mm')
      : null,
    getInstanceStatusLabel(i.status),
    i.assignedUser.displayName,
  ];

  const onClickRow = (i: FormInstance) =>
    push(`/admin/${appSpace}/forms/${formId}/instances/${i._id}`);

  return (
    <>
      <AppContext
        title={__('Zoznam vyplnených formulárov')}
        breadcrumbs={[
          { name: __('Zoznam formulárov'), url: `/admin/${appSpace}/forms` },
        ]}
      />
      <ScrollLayout
        topFixed={
          <div style={{ background: 'white' }}>
            <Tabber view={'instances'} />
            <Filters
              filters={[
                {
                  type: 'select' as const,
                  name: 'statuses',
                  options: statusesCodeList,
                  multiple: true,
                  label: __('Stav'),
                },
              ]}
              onChange={(values: any) => {
                setParameter({
                  statuses: (values.statuses || []).map(
                    (s: CodelistItem) => s.value,
                  ),
                });
              }}
              value={Object.keys(query)
                .filter(k => k !== 'offset')
                .reduce((acc, k) => {
                  if (k === 'statuses') {
                    const statuses = (query[k] as Array<string>).reduce(
                      (acc: any[], status) => {
                        const codelistStatus = statusesCodeList.find(
                          i => i.value === status,
                        );
                        if (codelistStatus) {
                          return [...acc, codelistStatus];
                        }
                        return acc;
                      },
                      [],
                    );
                    return { ...acc, [k]: statuses };
                  }
                  return {
                    ...acc,
                    [k]:
                      typeof query[k] === 'number'
                        ? String(query[k])
                        : query[k],
                  };
                }, {})}
            />
          </div>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={Number(query.offset)}
                limit={config.DEFAULT_LIST_LIMIT}
                onChangeOffset={offset => {
                  setParameter({ offset });
                }}
                total={total || 0}
              />
            </ContextBarItem>
            <ContextBarSpacer />
          </ContextBar>
        }
      >
        {isListCommiting ? (
          <LoaderWrapper>
            <Loader theme={theme} size="xl" />
          </LoaderWrapper>
        ) : (
          <BasicTable
            columns={[
              { header: __('Dátum prijatia') },
              { header: __('Stav') },
              { header: __('Meno a priezvisko') },
            ]}
            rows={instances}
            renderRow={renderRow}
            rowKey="_id"
            onClickRow={onClickRow}
          />
        )}
      </ScrollLayout>
    </>
  );
};

export default compose(
  withQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
      statuses: {
        type: QueryHocTypes.Array,
        defaultValue: [
          'NEW',
          'RECEIVED',
          'PROCESSING',
          'ACCEPTED',
          'REJECTED',
          'STORNO',
        ],
        delimiter: ',',
      },
    },
  }),
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(FormInstances);
