import FormGroup from '@sportnet/ui/lib/FormGroup';
import Label from '@sportnet/ui/lib/Label/Label';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';
import api, {
  FormField as IFormSegmentElement,
  FormInstanceUpload,
} from '../../../api';
import { activeAppspaceRoleSelector } from '../../../containers/App/selectors';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import FileInput from '../../FileInput/redux-form';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
  };
}

const mapStateToProps = (state: RootState) => ({
  role: activeAppspaceRoleSelector(state),
});

const FileUploadFormElement: React.FC<
  OwnProps & ReturnType<typeof mapStateToProps>
> = ({ data, name, parameters: { appSpace, formId, instanceId }, role }) => {
  const downloadFile = async (file: FormInstanceUpload) => {
    try {
      if (role === 'admin') {
        const res = await api.adminGetFormInstanceUploadDownloader(
          appSpace,
          formId,
          instanceId,
          file._id,
        );
        window.open(res.url, '_blank');
      } else if (role === 'controller') {
        const res = await api.adminGetControllerFormInstanceUploadDownloader(
          appSpace,
          formId,
          instanceId,
          file._id,
        );
        window.open(res.url, '_blank');
      }
    } catch (e) {
      alert(__('Súbor nie je možné stiahnuť'));
    }
  };

  return (
    <FormGroup>
      {data.label && <Label>{data.label}</Label>}
      <div>
        <Field
          disabled
          id={name}
          name={name}
          component={FileInput}
          multiple
          onFileClick={downloadFile}
        />
      </div>
    </FormGroup>
  );
};

export default compose(connect(mapStateToProps))(FileUploadFormElement);
