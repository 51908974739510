import Domains from '@sportnet/ui/lib/Domains';
import * as cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import api from '../../../api';
import { ACCESS_TOKEN_COOKIE_NAME } from '../../../authorization';
import config from '../../../config';
import { CustomThunkDispatch } from '../../../configureStore';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import { RootState } from '../../../rootReducer';

interface DomainComponentResponse {
  _id: string;
  status: string;
  ppo: string;
  payload?: { [key: string]: any };
  cert: { [key: string]: any };
  appId: string;
  aliases?: string[];
}

const mapStateToProps = (state: RootState) => ({
  appSpace: activeAppspaceIdSelector(state),
});

type Props = { domain: null | string } & ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
  };

const DomainForm: React.FC<Props> = ({ appSpace, domain }) => (
  <Domains
    accessToken={cookies.get(ACCESS_TOKEN_COOKIE_NAME) || ''}
    appId={config.APP_ID}
    appSpace={appSpace}
    onChange={async (payload: DomainComponentResponse | null) => {
      let domain = payload ? payload._id : null;
      await api.adminEditSettings(appSpace, {}, { domain: domain || '' });
    }}
    domain={domain || undefined}
  />
);

export default compose(connect(mapStateToProps))(DomainForm);
