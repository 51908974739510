import * as React from 'react';
import styled, { css } from 'styled-components';

interface ContentProps {
  width: null | number;
  sticked: boolean;
  stickedToBottom: boolean;
  offset: number;
}

const Content = styled('div')<ContentProps>`
  ${({ sticked, stickedToBottom, offset }) => {
    if (stickedToBottom) {
      return css`
        position: absolute;
        bottom: 0;
      `;
    }

    if (sticked) {
      return css`
        position: fixed;
        top: ${offset}px;
      `;
    }

    return css`
      position: static;
    `;
  }};
  width: ${({ width }) => (width === null ? 'auto' : `${width}px`)};
`;

interface OwnProps {
  context: any;
  parent: any;
  offset: number;
  active?: boolean;
}

type Props = OwnProps;

const Sticky: React.FC<Props> = ({
  context,
  parent,
  offset,
  active = true,
  children,
}) => {
  const [sticked, setSticked] = React.useState(false);
  const [stickedToBottom, setStickedToBottom] = React.useState(false);
  const [width, setWidth] = React.useState<null | number>(null);

  React.useEffect(() => {
    handleUpdate();
    (parent || window).addEventListener('scroll', handleUpdate);
    (parent || window).addEventListener('resize', handleUpdate);
  });

  let triggerRef: HTMLDivElement | null = null;
  let contentRef: HTMLDivElement | null = null;

  const handleUpdate = () => {
    if (!active) return;
    const contextRect = (context || document.body).getBoundingClientRect();
    const triggerRect = triggerRef ? triggerRef.getBoundingClientRect() : null;
    const contentRect = contentRef ? contentRef.getBoundingClientRect() : null;

    if (triggerRect && triggerRect.top <= offset) {
      setSticked(true);
      setWidth(triggerRect.width);
    }

    if (contentRect && contextRect.bottom <= contentRect.bottom) {
      setStickedToBottom(true);
    }

    if (contentRect && contentRect.top > offset) {
      setStickedToBottom(false);
    }

    if (contentRect && contentRect.height > contextRect.height) {
      setSticked(false);
      setStickedToBottom(false);
    }
  };

  return (
    <React.Fragment>
      <div ref={el => (triggerRef = el)} />
      {active ? (
        <Content
          sticked={sticked}
          offset={offset}
          stickedToBottom={stickedToBottom}
          ref={el => (contentRef = el)}
          width={width}
        >
          {children}
        </Content>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default Sticky;
