import Col, { Row } from '@sportnet/ui/lib/Grid';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { FormField } from '../../../api';
import { __ } from '../../../utilities';
import CheckboxFormElement from '../../FormElements/CheckboxFormElement';
import SelectFormElement from '../../FormElements/SelectFormElement';
import TextInputFormElement from '../../FormElements/TextInputFormElement';
import legalForms from './legalForms';

interface OwnProps {
  data: FormField;
  name: string;
}

const Wrapper = styled('div')`
  position: relative;
`;

const CompanyInfoGroupFormElement: React.FC<OwnProps & Theme> = ({ name }) => (
  <Wrapper>
    <Row>
      <Col xs={12} s={6}>
        <TextInputFormElement
          name={`${name}.companyName`}
          data={{
            _id: 'companyName',
            label: __('Názov'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={12} s={6}>
        <TextInputFormElement
          name={`${name}.companyAddress`}
          data={{
            _id: 'companyAddress',
            label: __('Sídlo'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={12} s={6}>
        <SelectFormElement
          name={`${name}.legalForm`}
          data={{
            _id: 'legalForm',
            label: __('Právna forma'),
            validations: [{ type: 'required' }],
            type: 'Select',
            options: legalForms,
          }}
        />
      </Col>
      <Col xs={12} s={3}>
        <TextInputFormElement
          name={`${name}.ico`}
          data={{
            _id: 'ico',
            label: __('IČO'),
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={12} s={3}>
        <TextInputFormElement
          name={`${name}.dic`}
          data={{
            _id: 'dic',
            label: __('DIČ'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={12}>
        <CheckboxFormElement
          name={`${name}.vatPayer`}
          data={{
            _id: 'vatPayer',
            label: __('Platca DPH'),
            type: 'Checkbox',
          }}
        />
      </Col>
    </Row>
  </Wrapper>
);

export default withTheme(CompanyInfoGroupFormElement);
