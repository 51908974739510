import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Label from '@sportnet/ui/lib/Label/Label';
import { em } from 'polished';
import * as React from 'react';
import { Field } from 'redux-form';
import { FormField as IFormSegmentElement } from '../../../api';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
}

const CheckboxGroupWithInputFormElement: React.FC<OwnProps> = ({
  data,
  name,
}) => (
  <>
    {data.label && <Label>{data.label}</Label>}
    <div style={{ paddingTop: em(10) }}>
      {(data.options || []).map((option, idx) => (
        <Field
          key={option}
          name={`${name}[${option}]`}
          component={FormField}
          label={option}
          type="checkbox"
          disabled
        />
      ))}
      <Field
        name={`${name}[Iné]`}
        component={FormField}
        label="Iné"
        type="checkbox"
        disabled
      />
      <Field readOnly name={`${name}[Iné-Text]`} component={FormField} />
    </div>
  </>
);

export default CheckboxGroupWithInputFormElement;
