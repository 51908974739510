import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import api from './api';
import configureStore from './configureStore';
import App from './containers/App';
import SportnetApi from './sportnetApi';
import GlobalStyles from './theme/globalStyles';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';

SportnetApi.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Router>
          <Switch>
            <App />
          </Switch>
        </Router>
      </>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
