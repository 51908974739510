import { AnyAction } from 'redux';
import { getProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { User_Group } from '../../../src/sportnetApi';
import { Form, FormInstanceAdmin } from '../../api';
import { ApplicationInfo, Appspace, User } from '../../library/App';
import * as actions from './actions';

export type ApplicationState = Readonly<{
  applicationInfo: ApplicationInfo | null;
  appspaces: ReadonlyArray<Appspace>;
  authUser: User | null;
  userGroups: User_Group[];
  activeAppspace: string | null;
  breadcrumbs: ReadonlyArray<JSX.Element | string>;
  acl: {};
}>;

const INITIAL_STATE: ApplicationState = {
  applicationInfo: null,
  appspaces: [],
  userGroups: [],
  authUser: null,
  activeAppspace: null,
  breadcrumbs: [],
  acl: {},
};

export const applicationReducer = reducerWithInitialState(INITIAL_STATE)
  .case(
    actions.setAppspaces,
    (state, appspaces): ApplicationState => ({
      ...state,
      appspaces,
    }),
  )
  .case(
    actions.loadAppSpaceUserGroups.async.done,
    (state, response): ApplicationState => ({
      ...state,
      userGroups: response.result.groups,
    }),
  )
  .case(
    actions.setActiveAppspace,
    (state, activeAppspace): ApplicationState => ({
      ...state,
      activeAppspace,
    }),
  )
  .case(
    actions.setAuthUser,
    (state, authorizationResponse): ApplicationState => ({
      ...state,
      authUser: authorizationResponse.user,
    }),
  )
  .case(
    actions.setApplicationInfo,
    (state, applicationInfo): ApplicationState => ({
      ...state,
      applicationInfo,
    }),
  )
  .case(
    actions.removeActiveAppspace,
    (state): ApplicationState => ({
      ...state,
      activeAppspace: null,
    }),
  );

type EntitiesState = Readonly<{
  forms: Readonly<{
    [key: string]: Readonly<Form>;
  }>;
  formInstances: Readonly<{
    [key: string]: Readonly<FormInstanceAdmin>;
  }>;
}>;

export const entitiesReducer = (
  state: EntitiesState = {
    forms: {},
    formInstances: {},
  },
  action: AnyAction,
) => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return {
      ...state,
      ...Object.keys(action.payload.result.entities).reduce((acc, entity) => {
        return {
          ...acc,
          [entity]: {
            ...getProp(state, [entity], {}),
            ...action.payload.result.entities[entity],
          },
        };
      }, {}),
    };
  }
  return state;
};
