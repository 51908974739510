import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import {
  ApplicationInfo,
  Appspace,
  AuthorizationResponse,
} from '../../library/App';
import { RootState } from '../../rootReducer';
import sportnetApi, { User_Group } from '../../sportnetApi';

const create = actionCreatorFactory(config.APPLICATION_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAppspaces = create<Appspace[]>('SET_APPSPACES');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setAuthUser = create<AuthorizationResponse>('SET_AUTH_USER');

export const setApplicationInfo = create<ApplicationInfo>(
  'SET_APPLICATION_INFO',
);

export const setBreadcrumbs = create<Array<JSX.Element | string>>(
  'SET_BREADCRUMBS',
);

export const updateEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'UPDATE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const loadAppSpaceUserGroups = createAsync<
  { appSpace: string },
  { groups: User_Group[] }
>('LOAD_APPSPACE_USER_GROUPS', async parameters => {
  const { appSpace } = parameters;
  const { groups = [] } = await sportnetApi.getPublicAppSpaceGroups(appSpace);
  return { groups };
});

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});
