import * as React from 'react';
import { FormField, FormSegment } from '../../api';
import CheckboxFormElement from '../FormElements/CheckboxFormElement';
import CheckboxGroupWithInputFormElement from '../FormElements/CheckboxGroupWithInputFormElement';
import DateInputFormElement from '../FormElements/DateInputFormElement';
import DecimalInputFormElement from '../FormElements/DecimalInputFormElement';
import EmailInputFormElement from '../FormElements/EmailInputFormElement';
import FileUploadFormElement from '../FormElements/FileUploadFormElement';
import NumberInputFormElement from '../FormElements/NumberInputFormElement';
import SelectFormElement from '../FormElements/SelectFormElement';
import TextareaInputFormElement from '../FormElements/TextareaInputFormElement';
import TextInputFormElement from '../FormElements/TextInputFormElement';
import CompanyInfoGroupFormElement from '../GroupFormElements/CompanyInfoGroupFormElement';
import PersonGroupFormElement from '../GroupFormElements/PersonGroupFormElement';

interface OwnProps {
  segment: FormSegment;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
  };
}

const FormElementsResolver: React.FC<OwnProps> = ({
  segment: { fields = [] },
  segment,
  parameters,
}) => {
  const renderField = (field: FormField, segmentId: string) => {
    const commonProps = {
      key: `${field._id}`,
      name: `values.${field._id}`,
      data: field,
      parameters,
    };
    switch (field.type) {
      case 'TextInput':
        return <TextInputFormElement {...commonProps} />;
      case 'NumberInput':
        return <NumberInputFormElement {...commonProps} />;
      case 'DecimalInput':
        return <DecimalInputFormElement {...commonProps} />;
      case 'DatePicker':
        return <DateInputFormElement {...commonProps} />;
      case 'EmailInput':
        return <EmailInputFormElement {...commonProps} />;
      case 'TextArea':
        return <TextareaInputFormElement {...commonProps} />;
      case 'Select':
        return <SelectFormElement {...commonProps} />;
      case 'Checkbox':
        return <CheckboxFormElement {...commonProps} />;
      case 'CheckboxGroupWithInput':
        return <CheckboxGroupWithInputFormElement {...commonProps} />;
      case 'FileUpload':
        return <FileUploadFormElement {...commonProps} />;
      case 'CompanyData':
        return <CompanyInfoGroupFormElement {...commonProps} />;
      case 'PersonContactData':
        return <PersonGroupFormElement {...commonProps} />;
      default:
        return null;
    }
  };
  return <>{fields.map(field => renderField(field, segment._id))}</>;
};

export default FormElementsResolver;
