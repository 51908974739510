import * as React from 'react';
import { Route } from 'react-router-dom';
import Authorization from '../../authorization';

const App: React.FC = () => (
  <>
    <Route exact path="/" component={Authorization} />
    <Route path="/:mode/:appspace/:path" component={Authorization} />
  </>
);

export default App;
