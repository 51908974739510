import { createGlobalStyle } from 'styled-components';

// tslint:disable-next-line
const global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');
  html, body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background: #fff;
    height: 100%;
  }
  #root {
    height: 100%;
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
`;

export default global;
