import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { FormHeader } from '../../api';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { updateEntities } from '../App/actions';
import { activeAppspaceIdSelector } from '../App/selectors';

const create = actionCreatorFactory(config.FORMS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadForms = createAsync<
  {
    offset?: number;
    limit: number;
  },
  { forms: FormHeader[]; total: number }
>('LOAD_FORMS', async (params, dispatch, getState, { api }) => {
  const { offset, limit } = params;
  const parameters: {
    offset?: number;
    limit: number;
  } = {
    limit,
  };
  if (offset) {
    parameters.offset = offset;
  }
  const appSpace = activeAppspaceIdSelector(getState());
  const response = await api.adminGetForms(appSpace, parameters);
  dispatch(
    updateEntities({
      forms: (response.forms || []).reduce(
        (acc, i) => ({
          ...acc,
          [i._id || '']: i,
        }),
        {},
      ),
    }),
  );
  return { forms: response.forms || [], total: response.total };
});
