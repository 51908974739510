import Button from '@sportnet/ui/lib/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/lib/ContextBar';
import Header from '@sportnet/ui/lib/Header';
import ScrollLayout from '@sportnet/ui/lib/Layouts/ScrollLayout';
import { Loader } from '@sportnet/ui/lib/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import AppContext from '@sportnet/ui/lib/TheLayout/AppContext';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import Select from '@sportnet/ui/lib/TheSelect';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { formValueSelector, submit } from 'redux-form';
import styled, { withTheme } from 'styled-components';
import api, { ChangelogItem, FormInstanceStatus } from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import Sticky from '../../components/Sticky';
import { CustomThunkDispatch } from '../../configureStore';
import { activeAppspaceRoleSelector } from '../../containers/App/selectors';
import { loadFormInstanceById } from '../../containers/FormInstances/actions';
import { formInstanceByIdSelector } from '../../containers/FormInstances/selectors';
import { RootState } from '../../rootReducer';
import sportnetApi, { User_Group } from '../../sportnetApi';
import { __ } from '../../utilities';
import FormInstanceFormWrapper from './instanceFormWrapper';
import InstanceStatusChangeForm from './instanceStatusChangeForm';

const INSTANCE_STATUS_CHANGE_FORM_NAME = 'INSTANCE_STATUS_CHANGE_FORM';

const ContentWrapper = styled('div')`
  flex-basis: 100%;
  @media (min-width: ${em(1200)}) {
    flex-basis: 75%;
  }
`;

const StickyWrapper = styled('div')`
  display: none;
  @media (min-width: ${em(1200)}) {
    position: relative;
    margin-left: ${em(10)};
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    display: block;
  }
`;

const MobileWrapper = styled('div')`
  @media (min-width: ${em(1200)}) {
    display: none;
  }
`;

const StickyLogItem = styled('div')`
  padding: ${em(15)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 400;
  background: white;
  border-bottom: ${em(1)} solid #f9f9f9;
  :last-child {
    border-bottom: 0;
  }
  & > i {
    font-size: ${rem(12)};
    color: #bbb;
  }
  & > div {
    font-size: ${rem(12)};
  }
`;

const StickyItem = styled('div')`
  background: transparent;
  font-size: ${em(13)};
  box-shadow: 0px 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
  color: #555;
  font-weight: 300;
  border-radius: ${rem(5)};
  ${StickyLogItem}:first-child {
    border-top-right-radius: ${rem(5)};
    border-top-left-radius: ${rem(5)};
  }
  ${StickyLogItem}:last-child {
    border-bottom-right-radius: ${rem(5)};
    border-bottom-left-radius: ${rem(5)};
  }
`;

const Textarea = styled('textarea')`
  width: 100%;
  padding: ${rem(10)};
  font-size: ${rem(13)};
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
`;

type RouteProps = RouteComponentProps<{
  appSpace: string;
  formId: string;
  instanceId: string;
}>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const selector = formValueSelector(INSTANCE_STATUS_CHANGE_FORM_NAME);
  return {
    instance: formInstanceByIdSelector(props.match.params.instanceId)(state),
    currentNewStateValue: selector(state, 'status'),
    role: activeAppspaceRoleSelector(state),
  };
};

type Props = RouteProps & { dispatch: CustomThunkDispatch } & Theme &
  ReturnType<typeof mapStateToProps>;

export const getInstanceStatusLabel = (statusId: FormInstanceStatus) => {
  switch (statusId) {
    case 'DRAFT':
      return __('Rozpracovaný');
    case 'NEW':
      return __('Nový');
    case 'RECEIVED':
      return __('Prijatý');
    case 'PROCESSING':
      return __('Spracováva sa');
    case 'ACCEPTED':
      return __('Schválené');
    case 'REJECTED':
      return __('Zamietnuté');
    case 'STORNO':
      return __('Storno');
    default:
      return statusId;
  }
};

const FormInstanceDetail: React.FC<Props> = props => {
  const {
    match: {
      params: { appSpace, formId, instanceId },
    },
    theme,
    instance,
    dispatch,
    currentNewStateValue,
    role,
  } = props;

  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [statusesCodelist, setStatusesCodelist] = React.useState<any[]>([]);
  const [modalOpened, setModalOpenState] = React.useState(false);

  const [appSpaceGroups, setAppSpaceGroups] = React.useState<User_Group[]>([]);

  const getCurrentStatusOptions = React.useCallback(() => {
    if (instance) {
      const status = statusesCodelist.find(i => i.value === instance.status);
      if (status) {
        return statusesCodelist.filter(
          i => status.payload && status.payload.follows.includes(i.value),
        );
      }
    }
    return [];
  }, [instance, statusesCodelist]);

  const changeFormStatus = async (values: {
    reason?: string;
    status: { value: FormInstanceStatus };
  }) => {
    setIsSubmitting(true);
    try {
      const data: { status: FormInstanceStatus; statusComment?: string } = {
        status: values.status.value,
      };
      if (values.reason) {
        data.statusComment = values.reason;
      }
      if (role === 'admin') {
        await api.adminUpdateFormInstance(
          appSpace,
          formId,
          instanceId,
          {},
          data,
        );
      } else if (role === 'controller') {
        await api.adminUpdateControllerFormInstance(
          appSpace,
          formId,
          instanceId,
          {},
          data,
        );
      }
      setModalOpenState(false);
    } catch (e) {
      if (e.details && e.details.name === 'STATUS_ERROR') {
        alert(__('Aktuálny stav nie je možné zmeniť stav na zvolenú možnosť.'));
      } else if (e.details && e.details.name === 'MANDATORY_COMMENT') {
        alert(__('K zmene stavu je potrebné zadať poznámku.'));
      } else {
        alert(__('Nepodarilo sa zmeniť stav inštancie.'));
      }
    } finally {
      setIsSubmitting(false);
      loadData();
    }
  };

  const getComment = (i: ChangelogItem) => (
    <>
      <b>{getInstanceStatusLabel(i.after as FormInstanceStatus)}</b>
      {i.comment && <div>{i.comment}</div>}
      <i>{format(new Date(i.when), 'DD.MM.YYYY o HH:mm')}</i>
    </>
  );

  let internalCommentTimeout: any = null;

  function saveInternalComment(internalComment: string) {
    clearTimeout(internalCommentTimeout);
    internalCommentTimeout = setTimeout(() => {
      try {
        if (role === 'admin') {
          api.adminUpdateFormInstance(
            appSpace,
            formId,
            instanceId,
            {},
            { internalComment },
          );
        } else if (role === 'controller') {
          api.adminUpdateControllerFormInstance(
            appSpace,
            formId,
            instanceId,
            {},
            { internalComment },
          );
        }
      } catch (e) {
        //
      }
    }, 2000);
  }

  const loadData = React.useCallback(async () => {
    try {
      setIsFetching(true);
      await dispatch(loadFormInstanceById.action({ formId, instanceId }));
      await api.getFormInstanceStatusCodelist(appSpace).then(({ codelist }) => {
        setStatusesCodelist(codelist);
      });
      const { groups = [] } = await sportnetApi.getPublicAppSpaceGroups(
        appSpace,
      );
      setAppSpaceGroups(groups);
    } catch (e) {
      alert(__('Detail formulára nebolo možné získať.'));
    } finally {
      setIsFetching(false);
    }
  }, [formId, instanceId, dispatch, appSpace]);

  React.useEffect(() => {
    loadData();
  }, [formId, instanceId, dispatch, loadData]);

  const groups = appSpaceGroups.reduce((acc, g) => {
    if ((instance.groups || []).includes(g._id || '')) {
      return [...acc, { label: g.name || '', value: g._id || '' }];
    }
    return acc;
  }, []);

  return (
    <ScrollLayout
      bottomFixed={
        getCurrentStatusOptions().length > 0 && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button onClick={() => setModalOpenState(true)} primary>
                {__('Zmeniť stav')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <AppContext
        title={__('Detail vyplneného formulára')}
        breadcrumbs={[
          {
            url: `/admin/${appSpace}/forms/${formId}`,
            name: __('Nastavenia formulára'),
          },
        ]}
      />
      <div id="stickyParent">
        {!isFetching && !!instance && !!instance.values ? (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <ContentWrapper>
              <Segment>
                <MobileWrapper>
                  <Segment
                    raised
                    header={
                      <SegmentHeader size="xs" withSeparator>
                        {__('Kontrolné skupiny')}
                      </SegmentHeader>
                    }
                  >
                    <Select
                      multiple
                      options={appSpaceGroups.map(g => ({
                        label: g.name || '',
                        value: g._id || '',
                      }))}
                      onChange={() => {
                        //
                      }}
                      disabled
                      value={groups}
                    />
                  </Segment>
                  <Segment
                    raised
                    header={
                      <SegmentHeader size="xs" withSeparator>
                        {__('Interné poznámky')}
                      </SegmentHeader>
                    }
                  >
                    <Textarea
                      onChange={e => {
                        saveInternalComment(e.target.value);
                      }}
                      rows={8}
                      defaultValue={instance.internalComment}
                    />
                    {(instance.changelog || []).length > 0 && (
                      <>
                        <div style={{ marginTop: rem(10) }}>
                          <div>
                            <StickyItem>
                              {(instance.changelog || []).map(i => (
                                <StickyLogItem>{getComment(i)}</StickyLogItem>
                              ))}
                            </StickyItem>
                          </div>
                        </div>
                      </>
                    )}
                  </Segment>
                </MobileWrapper>
                <FormInstanceFormWrapper
                  parameters={{ appSpace, formId, instanceId }}
                  initialValues={{
                    ...instance,
                    values: (instance.values || []).reduce(
                      (acc: { [key: string]: any }, value) => {
                        const fields = instance.form.segments.reduce(
                          (acc, segment) => [...acc, ...segment.fields],
                          [],
                        );
                        const field = fields.find(
                          field => field._id === value.fieldId,
                        );

                        const keys = value.fieldId.split('.');
                        return {
                          ...acc,
                          [keys[0]]: {
                            ...(acc[keys[0]] || {}),
                            [keys[1]]:
                              field &&
                              field.type === 'CheckboxGroupWithInput' &&
                              Array.isArray(value.value)
                                ? (value.value || []).reduce(
                                    (
                                      valuesAcc: { [key: string]: boolean },
                                      value: string,
                                    ) => ({ ...valuesAcc, [value]: true }),
                                    {},
                                  )
                                : value.value,
                          },
                        };
                      },
                      {},
                    ),
                  }}
                  form={instance._id}
                />
              </Segment>
            </ContentWrapper>
            <StickyWrapper id="stickyWrapper">
              <Sticky
                offset={74}
                context={document.getElementById('stickyWrapper')}
                parent={
                  document.getElementById('stickyParent')
                    ? document.getElementById('stickyParent')!.parentElement
                    : null
                }
              >
                <Segment
                  raised
                  header={
                    <SegmentHeader size="xs" withSeparator>
                      {__('Kontrolné skupiny')}
                    </SegmentHeader>
                  }
                >
                  <Select
                    multiple
                    options={appSpaceGroups.map(g => ({
                      label: g.name || '',
                      value: g._id || '',
                    }))}
                    onChange={() => {
                      //
                    }}
                    disabled
                    value={groups}
                  />
                </Segment>
                <Segment
                  raised
                  header={
                    <SegmentHeader size="xs" withSeparator>
                      {__('Interné poznámky')}
                    </SegmentHeader>
                  }
                >
                  <Textarea
                    onChange={e => {
                      saveInternalComment(e.target.value);
                    }}
                    rows={8}
                    defaultValue={instance.internalComment}
                  />
                </Segment>
                {(instance.changelog || []).length > 0 && (
                  <>
                    <div style={{ marginTop: rem(10) }}>
                      <div>
                        <StickyItem>
                          {(instance.changelog || []).map(i => (
                            <StickyLogItem>{getComment(i)}</StickyLogItem>
                          ))}
                        </StickyItem>
                      </div>
                    </div>
                  </>
                )}
              </Sticky>
            </StickyWrapper>
          </div>
        ) : (
          <LoaderWrapper>
            <Loader theme={theme} size="xl" />
          </LoaderWrapper>
        )}
      </div>
      {modalOpened && (
        <Modal
          isOpen={modalOpened}
          handleClose={() => setModalOpenState(false)}
        >
          <ModalContent>
            <Header size="xs" withSeparator>
              {__('Zmena stavu')}
            </Header>
            <InstanceStatusChangeForm
              form={INSTANCE_STATUS_CHANGE_FORM_NAME}
              statusOptions={getCurrentStatusOptions()}
              status={currentNewStateValue}
              onSubmit={changeFormStatus}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={() => setModalOpenState(false)}>
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary
                loading={isSubmitting}
                onClick={() => {
                  dispatch(submit(INSTANCE_STATUS_CHANGE_FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
      )}
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(FormInstanceDetail);
