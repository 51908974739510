import BasicTable from '@sportnet/ui/lib/BasicTable';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/lib/ContextBar';
import ScrollLayout from '@sportnet/ui/lib/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/lib/Loader';
import Paginator from '@sportnet/ui/lib/Paginator';
import AppContext from '@sportnet/ui/lib/TheLayout/AppContext';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { commit, CommitError, getListTotal, isCommiting } from 'redux-list';
import { FormHeader } from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import { initializeOrSetListParams } from '../../containers/App/actions';
import { activeAppspaceIdSelector } from '../../containers/App/selectors';
import { loadForms } from '../../containers/Forms/actions';
import { formsSelector } from '../../containers/Forms/selectors';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';

const LIST_NAME = 'FORMS_LIST';

const mapStateToProps = (state: RootState) => ({
  forms: formsSelector(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  isListCommiting: isCommiting(LIST_NAME)(state),
  appSpace: activeAppspaceIdSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
  dispatch: CustomThunkDispatch;
} & QueryHocInterface &
  RouteComponentProps<{ appSpace: string }>;

const FormsList: React.FC<Props> = ({
  query,
  setParameter,
  forms,
  total,
  isListCommiting,
  appSpace,
  dispatch,
  serializedQuery,
  history: { push },
}) => {
  React.useEffect(() => {
    dispatch(
      initializeOrSetListParams.action({
        listName: LIST_NAME,
        params: query,
      }),
    );
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(
              loadForms.action({
                ...query,
                limit: config.DEFAULT_LIST_LIMIT,
              }),
            );
            return {
              total: res.total,
              results: res.forms.map(a => a._id),
              nextOffset: null,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  }, [serializedQuery, dispatch, query]);

  return (
    <>
      <AppContext title={__('Zoznam formulárov')} />
      <ScrollLayout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={Number(query.offset)}
                limit={config.DEFAULT_LIST_LIMIT}
                onChangeOffset={offset => {
                  setParameter({ offset });
                }}
                total={total || 0}
              />
            </ContextBarItem>
            <ContextBarSpacer />
          </ContextBar>
        }
      >
        {isListCommiting ? (
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        ) : (
          <BasicTable
            columns={[{ header: __('Názov') }]}
            rows={forms}
            renderRow={(form: FormHeader) => [form.name]}
            rowKey="_id"
            onClickRow={(form: FormHeader) => {
              push(`/admin/${appSpace}/forms/${form.formId}`);
            }}
          />
        )}
      </ScrollLayout>
    </>
  );
};

export default compose(
  connectToQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
    },
  }),
  withRouter,
  connect(mapStateToProps),
)(FormsList);
