export default [
  'Akciová spoločnosť',
  'Banka-štátny peňažný ústav',
  'Cirkevná organizácia',
  'Doplnková dôchodková poisťovňa',
  'Družstvo',
  'Európska spoločnosť',
  'Európske družstvo',
  'Európske zoskupenie hospodárskych záujmov',
  'Európske zoskupenie územnej spolupráce',
  'Fondy',
  'Fyzická osoba-príležitostne činná-zapísaná v registri daňového informačného systému',
  'Iná organizácia verejnej správy',
  'Komanditná spoločnosť',
  'Komoditná burza',
  'Komora (s výnimkou profesných komôr)',
  'Medzinárodné organizácie a združenia',
  'Miestna jednotka bez právnej spôsobilosti',
  'Nadácia',
  'Národná banka Slovenska',
  'Neinvestičný fond',
  'Nešpecifikovaná právna form',
  'Nezisková organizácia',
  'Obec (obecný úrad), mesto (mestský úrad)',
  'Podnikateľ-fyzická osoba-nezapís.v OR-podnikajúca súčasne ako osoba so slobodným povolaním',
  'Podnikateľ-fyzická osoba-nezapís.v OR-podnikajúca súčasne ako sam.hosp.roľník',
  'Podnikateľ-fyzická osoba-nezapísaný v obchodnom registri',
  'Podnikateľ-fyzická osoba-zapís.v OR-podnikajúca súčasne ako osoba so slobodným povolaním',
  'Podnikateľ-fyzická osoba-zapís.v OR-podnikajúca súčasne ako sam.hosp.roľník',
  'Podnikateľ-fyzická osoba-zapísaný v obchodnom registri',
  'Politická strana, politické hnutie',
  'Príspevková organizácia',
  'Rozpočtová organizácia',
  'Samosprávny kraj (úrad samosprávneho kraja)',
  'Samostatne hospodáriaci roľník nezapísaný v obchodnom registri',
  'Samostatne hospodáriaci roľník zapísaný v obchodnom registri',
  'Slobodné povolanie-fyzická osoba podnikajúca na základe iného ako živnostenského zákona zapísaná v obchodnom registri',
  'Slobodné povolanie-fyzická osoba podnikajúca na základe iného ako živnostenského zákona',
  'Sociálna a zdravotné poisťovne',
  'Spoločenstvá vlastníkov pozemkov, bytov a pod.',
  'Spoločnosť s ručením obmedzeným',
  'Štátny podnik',
  'Stavovská organizácia-profesná komora',
  'Verejná obchodná spoločnosť',
  'Verejnoprávna inštitúcia',
  'Zahraničná osoba, fyzická osoba s bydliskom mimo územia SR',
  'Zahraničná osoba, právnická osoba so sídlom mimo územia SR',
  'Zahraničné kultúrne, informačné stredisko, rozhlasová, tlačová a televízna agentúra',
  'Zastúpenie zahraničnej právnickej osoby',
  'Zastupiteľské orgány iných štátov',
  'Záujmové združenie fyzických osôb bez právnej spôsobilosti',
  'Záujmové združenie právnických osôb',
  'Združenie (zväz, spolok, spoločnosť, klub ai.)',
];
