import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { FormField as IFormSegmentElement } from '../../../api';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
}

const DecimalInputFormElement: React.FC<OwnProps> = ({ data, name }) => (
  <Field
    type="number"
    name={name}
    readOnly
    format={(i: number) => (Number.isNaN(i) ? '' : i)}
    component={FormField}
    label={data.label}
  />
);

export default DecimalInputFormElement;
