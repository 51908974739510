import { Authorization } from '@sportnet/ui';
import { Theme } from '@sportnet/ui/lib/Themes/baseTheme';
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { withTheme } from 'styled-components';
import api from './api';
import config from './config';
import { CustomThunkDispatch } from './configureStore';
import {
  removeActiveAppspace,
  setActiveAppspace,
  setApplicationInfo,
  setAppspaces,
  setAuthUser,
} from './containers/App/actions';
import {
  activeAppspaceNameSelector,
  activeAppspaceSelector,
  applicationInfoSelector,
  authUserSelector,
} from './containers/App/selectors';
import {
  ApplicationInfo,
  Appspace,
  AuthorizationResponse,
} from './library/App';
import { RootState } from './rootReducer';
import ApplicationRoutes from './routes';
import SportnetApi from './sportnetApi';

export const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => ({
  activeAppspace: activeAppspaceSelector(state),
  application: applicationInfoSelector(state),
  organizationName: activeAppspaceNameSelector(state),
  user: authUserSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
}> &
  OwnProps & { theme: Theme } & IMapStateToProps;

const Routes: React.FC<Props> = ({
  activeAppspace,
  application,
  organizationName,
  dispatch,
  history: { push },
  user,
  match: {
    params: { appspace },
  },
}) => {
  const onAuthorized = (data: AuthorizationResponse) => {
    dispatch(setAuthUser(data));
  };

  const onAppspaceSelect = async (data: Appspace) => {
    dispatch(
      setActiveAppspace(data.org_profile ? data.org_profile._id || '' : ''),
    );
    push(`/admin/${getProp(data, ['org_profile', '_id'])}/forms`);
  };

  const onAppInfoReceived = (data: ApplicationInfo) => {
    dispatch(setApplicationInfo(data));
  };

  const onAppspacesReceived = (data: Appspace[]) => {
    dispatch(setAppspaces(data));
  };

  React.useEffect(() => {
    const token = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
    SportnetApi.setToken(token);
    api.setToken(token);
    if (appspace) {
      dispatch(setActiveAppspace(appspace));
      SportnetApi.meAppSpacesForApp(config.APP_ID)
        .then(res => {
          dispatch(setAppspaces(res.appspaces || []));
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      dispatch(removeActiveAppspace());
    }
  }, [appspace, dispatch]);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  return (
    <Authorization
      accessToken={accessToken}
      appId={config.APP_ID}
      appspace={activeAppspace}
      onAppspaceSelect={onAppspaceSelect}
      onAuthorized={onAuthorized}
      onAppInfoReceived={onAppInfoReceived}
      onAppspacesReceived={onAppspacesReceived}
      scope="profile,messages,groups"
    >
      <ApplicationRoutes
        application={application}
        activeAppspace={activeAppspace}
        appspace={appspace}
        organizationName={organizationName || ''}
        user={user}
      />
    </Authorization>
  );
};

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(Routes);
