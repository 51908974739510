import Tabber from '@sportnet/ui/lib/Tabber';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { __ } from '../../utilities';

type Props = RouteComponentProps<{
  formId: string;
  appSpace: string;
}> & { view: string };

const Tabs: React.FC<Props> = ({
  history: { push },
  match: {
    params: { formId, appSpace },
  },
  view,
}) => {
  const setActiveTab = (idx: string) => {
    const tabs = getTabs();
    const tab = Object.keys(tabs).find(t => tabs[t].label === idx);
    if (tab) {
      push(`/admin/${appSpace}/forms/${formId}/${tab}`);
    }
  };

  const getTabs = () => {
    const tabs: {
      [key: string]: {
        label: string;
      };
    } = {};
    if (formId !== 'new') {
      tabs.instances = {
        label: __('Vyplnené formuláre'),
      };
    }
    return tabs;
  };

  const tabs = getTabs();
  const tab = Object.keys(tabs).find(t => t === (view || 'instances'));

  return (
    <Tabber
      active={tab && tabs[tab].label}
      onClickItem={setActiveTab}
      items={Object.keys(tabs).map(t => tabs[t].label)}
    />
  );
};

export default compose(withRouter)(Tabs);
