import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import { entitiesSelector } from '../App/selectors';

export const formsSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => (ids ? at(entities.forms, ids) : []),
  );
