import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { FormField as IFormSegmentElement } from '../../../api';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
}

const EmailInputFormElement: React.FC<OwnProps> = ({ data, name }) => (
  <Field
    name={name}
    type="email"
    component={FormField}
    label={data.label}
    readOnly
  />
);

export default EmailInputFormElement;
