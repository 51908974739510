import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { FormSegment } from '../../api';
import FormElementsResolver from '../../components/FormElementsResolver';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';

type OwnProps = {
  form: string;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
  };
};

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const selector = formValueSelector(props.form);
  return {
    segments: selector(state, 'form.segments') || [],
  };
};

const FormInstanceFormWrapper: React.FC<
  ReturnType<typeof mapStateToProps> & OwnProps
> = ({ segments, parameters }) => (
  <>
    <Segment
      raised
      header={
        <SegmentHeader size="s" withSeparator>
          {__('Základné údaje')}
        </SegmentHeader>
      }
    >
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            label={__('Názov formulára')}
            name="form.name"
            readOnly
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            component={FormField}
            label={__('Meno a priezvisko')}
            name="assignedUser.displayName"
            readOnly
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            component={FormField}
            label={__('Dátum odoslania')}
            name="finishDT"
            readOnly
            type="date"
          />
        </Col>
      </Row>
    </Segment>
    {segments.map((segment: FormSegment) => (
      <Segment
        key={segment._id}
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {segment.label}
          </SegmentHeader>
        }
      >
        <FormElementsResolver parameters={parameters} segment={segment} />
      </Segment>
    ))}
  </>
);

export default compose<any, any, any>(
  reduxForm({}),
  connect(mapStateToProps),
)(FormInstanceFormWrapper);
