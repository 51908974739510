import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import { entitiesSelector } from '../App/selectors';

export const formInstancesSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => (ids ? at(entities.formInstances, ids) : []),
  );

export const formInstanceByIdSelector = (id: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.formInstances[id],
  );
