import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import api from './api';
import rootReducer, { RootState } from './rootReducer';
import SportnetApi from './sportnetApi';

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

export const history = createBrowserHistory();

const extraArgument = { SportnetApi, api };
export type ExtraArgumentType = typeof extraArgument;
const thunk: ThunkMiddleware<{}, AnyAction> = thunkMiddleware.withExtraArgument(
  extraArgument,
);

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), thunk),
);

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<RootState, ExtraArgumentType, AnyAction>,
  getState: () => RootState,
  extraArgument: ExtraArgumentType,
) => R;

export type CustomThunkDispatch = ThunkDispatch<
  RootState,
  ExtraArgumentType,
  AnyAction
>;

export default function configureStore(initialState?: object) {
  return createStore(
    connectRouter(history)(rootReducer),
    initialState!,
    enhancer,
  );
}
