import Icon from '@sportnet/ui/lib/Icon';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { __ } from '../../utilities';
import { FileObjectDefinition } from './definitions';
import Dropzone from './dropzone';
import FileLine from './file';

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled('label')<{ error?: boolean | string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)} ${rem(10)};
  border-radius: ${rem(4)};
  border: ${rem(1.5)} dashed
    ${({ error, theme }) => (error ? theme.color.danger : theme.separatorColor)};
  cursor: pointer;
  flex-grow: 1;
`;

const FileList = styled.div`
  & > div:first-child {
    margin-top: ${rem(5)};
  }
`;

const DGTitle = styled.div``;

interface OwnProps {
  className?: string;
  id?: string | null;
  inputRef?: () => void;
  readOnly?: boolean;
  accept?: string;
  multiple?: boolean;
  onChange: (value: FileObjectDefinition[]) => void;
  value: FileObjectDefinition[];
  onDeleteCallback?: (file: any) => void;
  onFileClick?: ((file: any) => void) | null;
  error?: boolean | string;
  disabled?: boolean;
}

type Props = OwnProps & {};

const CompFileInput: React.FC<Props> = ({
  id,
  error,
  value,
  onChange,
  onFileClick,
  onDeleteCallback,
  disabled,
  readOnly,
  className,
  inputRef,
  accept,
  multiple,
}) => {
  const [stateValue, setValue] = React.useState('');
  let nextId = 0;

  const onChangeHandle = (files: FileList | null) => {
    const newValue = [...value];
    for (let i = 0; i < (files || []).length; i++) {
      const file = (files || [])[i];
      newValue.push({
        _id: getId(),
        name: file.name,
        size: file.size,
        file,
        _new: true,
      });
    }
    onChange(newValue);
    resetValue();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandle(e.target.files);
  };

  const onFileClickHandle = (file: any) => {
    if (onFileClick) {
      return () => onFileClick && onFileClick(file);
    }
    return null;
  };

  const onDeleteHandle = (file: FileObjectDefinition) => (e: Event) => {
    e.stopPropagation();
    onChange(value.filter(val => val._id !== file._id));
    if (onDeleteCallback) {
      onDeleteCallback(file);
    }
  };

  const getId = () => {
    const id = nextId;
    nextId += 1;
    return String(id);
  };

  const resetValue = () => setValue('');

  const renderFile = (file: FileObjectDefinition) => {
    return (
      <FileLine
        key={file._id || file.fileid}
        file={file}
        disabled={!!disabled}
        onDelete={onDeleteHandle(file)}
        onClick={onFileClickHandle(file)!}
      />
    );
  };

  const inputId = id;
  let files = [...value];
  const newFiles = files.reduce((acc, file) => {
    if (!file.fileid) {
      return [...acc, file];
    }
    return acc;
  }, []);
  if (newFiles.length && files.length !== newFiles.length) {
    files = newFiles;
  }

  return (
    <Wrapper>
      {!disabled && !readOnly ? (
        <Dropzone onDropFiles={onChangeHandle}>
          <Label htmlFor={inputId || ''} error={error}>
            <Icon size="l" name="file-download" />
            <DGTitle>{__('Sem vložte súbory')}</DGTitle>
          </Label>
          <FileInput
            value={stateValue}
            type="file"
            className={className}
            ref={inputRef}
            readOnly={!!readOnly}
            id={inputId || ''}
            accept={accept}
            multiple={multiple}
            onChange={onInputChange}
          />
          <FileList>{files.map(renderFile)}</FileList>
        </Dropzone>
      ) : (
        <>
          <FileInput
            value={stateValue}
            type="file"
            className={className}
            ref={inputRef}
            readOnly={!!readOnly}
            id={inputId || ''}
            accept={accept}
            multiple={multiple}
            onChange={onInputChange}
          />
          <FileList>{(value || []).map(renderFile)}</FileList>
        </>
      )}
    </Wrapper>
  );
};

CompFileInput.defaultProps = {
  className: '',
  id: null,
  readOnly: false,
  inputRef: () => {
    //
  },
  accept: '',
  multiple: false,
  onDeleteCallback: () => {
    //
  },
  onFileClick: null,
  error: false,
};

export default CompFileInput;
