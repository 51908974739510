import Col, { Row } from '@sportnet/ui/lib/Grid';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { FormField } from '../../../api';
import { __ } from '../../../utilities';
import EmailInputFormElement from '../../FormElements/EmailInputFormElement';
import TextInputFormElement from '../../FormElements/TextInputFormElement';

interface OwnProps {
  data: FormField;
  name: string;
}

const Wrapper = styled('div')`
  position: relative;
`;

const PersonGroupFormElement: React.FC<OwnProps & Theme> = ({ name }) => (
  <Wrapper>
    <Row>
      <Col xs={12}>
        <TextInputFormElement
          name={`${name}.contactPersonName`}
          data={{
            _id: 'contactPersonName',
            label: __('Meno a priezvisko'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={6}>
        <TextInputFormElement
          name={`${name}.contactPersonPhone`}
          data={{
            _id: 'contactPersonPhone',
            label: __('Telefónne číslo'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
      <Col xs={6}>
        <EmailInputFormElement
          name={`${name}.contactPersonEmail`}
          data={{
            _id: 'contactPersonEmail',
            label: __('Email'),
            validations: [{ type: 'required' }],
            type: 'TextInput',
          }}
        />
      </Col>
    </Row>
  </Wrapper>
);

export default withTheme(PersonGroupFormElement);
