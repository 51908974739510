import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  FormInstanceAdmin,
  FormInstanceHeader,
  FormInstanceStatus,
} from '../../api';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { updateEntities } from '../App/actions';
import {
  activeAppspaceIdSelector,
  activeAppspaceRoleSelector,
} from '../App/selectors';

const create = actionCreatorFactory(config.FORM_INSTANCES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadFormInstances = createAsync<
  {
    formId: string;
    offset?: number;
    limit: number;
    statuses?: FormInstanceStatus[];
  },
  { instances: FormInstanceHeader[]; total: number }
>('LOAD_FORM_INSTANCES', async (params, dispatch, getState, { api }) => {
  const { formId, offset, limit, statuses } = params;
  const parameters: {
    offset?: number;
    limit: number;
    status?: FormInstanceStatus[];
  } = { limit };
  if (offset) {
    parameters.offset = offset;
  }
  if (statuses) {
    parameters.status = statuses;
  }
  const appSpace = activeAppspaceIdSelector(getState());
  const role = activeAppspaceRoleSelector(getState());

  let instances: FormInstanceHeader[] = [];
  let total = 0;

  if (role === 'admin') {
    const res = await api.adminGetFormInstances(appSpace, formId, parameters);
    instances = res.instances || [];
    total = res.total;
  } else if (role === 'controller') {
    const res = await api.adminGetControllerFormInstances(
      appSpace,
      formId,
      parameters,
    );
    instances = res.instances || [];
    total = res.total;
  }
  dispatch(
    updateEntities({
      formInstances: instances.reduce(
        (acc, i) => ({
          ...acc,
          [i._id || '']: i,
        }),
        {},
      ),
    }),
  );
  return { instances, total };
});

export const loadFormInstanceById = createAsync<
  { formId: string; instanceId: string },
  FormInstanceAdmin
>('LOAD_FORM_INSTANCE_BY_ID', async (params, dispatch, getState, { api }) => {
  const { formId, instanceId } = params;

  const appSpace = activeAppspaceIdSelector(getState());
  const role = activeAppspaceRoleSelector(getState());

  if (role === 'admin') {
    const response = await api.adminGetFormInstance(
      appSpace,
      formId,
      instanceId,
    );
    dispatch(
      updateEntities({
        formInstances: { [response._id]: response },
      }),
    );
    return response;
  } else if (role === 'controller') {
    const response = await api.adminGetControllerFormInstance(
      appSpace,
      formId,
      instanceId,
    );
    dispatch(
      updateEntities({
        formInstances: { [response._id]: response },
      }),
    );
    return response;
  }
  throw new Error();
});
