import baseTheme from '@sportnet/ui/lib/Themes/baseTheme';

export const theme = {
  ...baseTheme,
};

export interface ThemeInterface {
  color: {
    primary: string;
  };
}
