import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { FormField as IFormSegmentElement } from '../../../api';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
}

const SelectFormElement: React.FC<OwnProps> = ({ data, name }) => (
  <Field
    name={name}
    disabled
    type="theselect"
    format={(i: any) => {
      if (i) {
        const option = (data.options || []).find(o => o === i);
        if (option) {
          return {
            value: i,
            label: i,
          };
        }
      }
      return '';
    }}
    options={(data.options || []).map(option => ({
      label: option,
      value: option,
    }))}
    component={FormField}
    label={data.label}
  />
);

export default SelectFormElement;
