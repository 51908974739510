import AppContext from '@sportnet/ui/lib/TheLayout/AppContext';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../../utilities';
import SettingsForm from './form';

type Props = RouteComponentProps<{ appspace: string }>;

const DomainSettings: React.FC<Props> = () => (
  <>
    <AppContext title={__('Nastavenia domény')} />
    <SettingsForm />
  </>
);

export default withRouter(DomainSettings);
