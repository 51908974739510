import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import api from '../../../api';
import { __ } from '../../../utilities';
import DomainForm from './domainForm';

export const SETTINGS_FORM_NAME = 'SETTINGS_FORM';

const SettingsForm: React.FC<RouteComponentProps<{ appspace: string }>> = ({
  match: {
    params: { appspace },
  },
}) => {
  const [domain, setDomain] = React.useState<string | null>(null);

  React.useEffect(() => {
    try {
      api.adminGetSettings(appspace).then(({ domain = null }) => {
        setDomain(domain || '');
      });
    } catch (e) {
      alert(__('Nepodarilo sa získať nastavenia'));
    }
  }, [appspace]);

  return (
    <Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Nastavenie domény')}
          </SegmentHeader>
        }
      >
        {domain !== null && <DomainForm domain={domain} />}
      </Segment>
    </Segment>
  );
};

export default compose(
  reduxForm({
    form: SETTINGS_FORM_NAME,
    enableReinitialize: true,
  }),
  withRouter,
  connect(),
)(SettingsForm);
