import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FileObjectDefinition } from './definitions';
import FileInput from './index';

const FileInputRedux: React.FC<
  WrappedFieldProps & {
    onFileClick: (file: FileObjectDefinition) => void;
  }
> = ({ input, meta: { error, touched }, onFileClick, ...restProps }) => {
  const value = input.value || [];
  return (
    <FileInput
      {...input}
      {...restProps}
      value={value}
      error={error && touched}
      onFileClick={onFileClick}
    />
  );
};

export default FileInputRedux;
