import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { RootState } from '../../rootReducer';

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

export const activeAppspaceIdSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppspace!,
);

const appspacesSelector = createSelector(
  applicationDomainSelector,
  substate => substate.appspaces,
);

export const activeAppspaceSelector = createSelector(
  appspacesSelector,
  activeAppspaceIdSelector,
  (appspaces, activeId) => {
    if (activeId && appspaces) {
      const ppo = appspaces.find(appspace => {
        return appspace.app_space === activeId;
      });
      return ppo;
    }
    return null;
  },
);

export const activeAppspaceRoleSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return getProp(substate, ['role']);
    }
    return null;
  },
);

export const activeAppspaceNameSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return substate.org_profile ? substate.org_profile.name || '' : '';
    }
    return null;
  },
);

export const activeAppspaceProfileSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return substate.org_profile;
    }
    return null;
  },
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  substate => substate.authUser,
);

export const applicationInfoSelector = createSelector(
  applicationDomainSelector,
  substate => substate.applicationInfo,
);

export const appSpaceGroupsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.userGroups,
);

export const entitiesSelector = (state: RootState) => {
  return state.entities;
};
