import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from 'redux-list';
import { applicationReducer, entitiesReducer } from './containers/App/reducer';

const rootReducer = combineReducers({
  application: applicationReducer,
  list: listReducer,
  entities: entitiesReducer,
  form: formReducer,
});

export default rootReducer;

export type RootState = {
  application: ReturnType<typeof applicationReducer>;
  list: ReturnType<typeof listReducer>;
  entities: ReturnType<typeof entitiesReducer>;
  form: ReturnType<typeof formReducer>;
};
