import * as React from 'react';
import { Route } from 'react-router';
import FormInstances from './Instances';

const FormDetail: React.FC = () => (
  <>
    <Route
      exact
      path="/admin/:appSpace/forms/:formId"
      component={FormInstances}
    />
    <Route
      exact
      path="/admin/:appSpace/forms/:formId/instances"
      component={FormInstances}
    />
  </>
);

export default FormDetail;
