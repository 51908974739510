export const __ = (text: string) => text;

export const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export const isNumber = (value: number) => {
  if (value && typeof value !== 'number') {
    return __('Zadajte prosím číslo.');
  }
  return null;
};

export const parseToNumber = (i: string) => {
  return !!i && !isNaN(Number(i)) ? Number(i) : i;
};

export const parseToFloat = (i: string) => {
  if (i.endsWith('.') || i.endsWith('.0')) {
    return i;
  }
  if (i.endsWith(',')) {
    return i.replace(',', '.');
  }
  return !!i && !isNaN(Number(i)) ? Number(i) : i;
};
