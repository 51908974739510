/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type GenericID = string;
export type FormHeader = {
  _id: GenericID;

  formId: GenericID;

  name: string;

  perex?: string;

  published?: boolean;

  createdDT?: string;

  updatedDT?: string;
};
export type FormBody = {
  content?: Array<{}>;

  segments: Array<FormSegment>;
};
export type FormSegment = {
  _id: GenericID;

  label: string;

  description?: string;

  fields: Array<FormField>;
};
export type FormField = {
  _id: GenericID;

  type: string;

  label?: string;

  options?: Array<string>;

  validations?: Array<FormFieldValidation>;
};
export type FormFieldValue = {
  fieldId: GenericID;

  value:
    | string
    | object
    | number
    | Array<string | object | number | boolean | null>
    | boolean
    | null;
};
export type FormFieldValidation = {
  type: string;
};
export type Form = {
  _id: GenericID;

  formId: GenericID;

  name: string;

  perex?: string;

  published?: boolean;

  createdDT?: string;

  updatedDT?: string;

  content?: Array<{}>;

  segments: Array<FormSegment>;
};
export type FormInstanceStatus =
  | 'DRAFT'
  | 'NEW'
  | 'RECEIVED'
  | 'PROCESSING'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'STORNO';
export type FormInstanceHeader = {
  _id: GenericID;

  status: FormInstanceStatus;

  assignedUser: {
    sportnetId: string;

    displayName: string;
  };

  createdDT?: string;

  updatedDT?: string;

  finishDT?: string;
};
export type FormInstance = {
  _id: GenericID;

  status: FormInstanceStatus;

  assignedUser: {
    sportnetId: string;

    displayName: string;
  };

  createdDT?: string;

  updatedDT?: string;

  finishDT?: string;

  form: Form;

  values: Array<FormFieldValue>;

  changelog?: Array<ChangelogItem>;
};
export type FormInstanceAdmin = {
  _id: GenericID;

  status: FormInstanceStatus;

  assignedUser: {
    sportnetId: string;

    displayName: string;
  };

  createdDT?: string;

  updatedDT?: string;

  finishDT?: string;

  form: Form;

  values: Array<FormFieldValue>;

  changelog?: Array<ChangelogItem>;

  internalComment?: string;
  groups?: string[];
};
export type FormInstanceUpload = {
  _id: GenericID;

  createdDT: string;

  name: string;

  type: string;

  path: string;
};
export type Pager = {
  total: number;

  limit: number;

  offset: number;
};
export type Settings_Base_googleAnalytics = {
  id: string;
};
export type Settings_Base_googleTagmanager = {
  id: string;
};
export type Settings_Base_theme = {
  color: {
    primary: string;
  };

  app?: {};
};
export type Settings_Base_pageTitle = string;
export type Settings_Base_logo = string | object | null;
export type Settings_Base_favicon = string | object | null;
export type Settings_Base_domain = string | null;
export type Settings_Edit = {
  googleAnalytics?: Settings_Base_googleAnalytics;

  googleTagmanager?: Settings_Base_googleTagmanager;

  theme?: Settings_Base_theme;

  pageTitle?: Settings_Base_pageTitle;

  logo?: Settings_Base_logo;

  favicon?: Settings_Base_favicon;

  domain?: Settings_Base_domain;
};
export type Settings = {
  googleAnalytics?: Settings_Base_googleAnalytics;

  googleTagmanager?: Settings_Base_googleTagmanager;

  theme: Settings_Base_theme;

  pageTitle: Settings_Base_pageTitle;

  favicon: Settings_Base_favicon;

  logo: Settings_Base_logo;

  domain?: Settings_Base_domain;
};
export type Downloader = {
  url: string;

  downloadId: string;
};
export type CodelistItem = {
  value: string | number | number;

  label: string;

  default?: boolean;

  payload?: {};
};
export type ChangelogItem = {
  when: string;

  who?: {
    sportnetId?: string;

    displayName?: string;
  };

  what: string;

  before?: string | object | number;

  after: string | object | number;

  comment?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = 'https://smartforms.sportnet.online/api/v1';
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Get Contentspace Details
   * @method
   * @name Api#adminGetSettings
   */
  adminGetSettings(appSpace: string, parameters: {} = {}): Promise<Settings> {
    let path = '/admin/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Contentspace Settings
   * @method
   * @name Api#adminEditSettings
   */
  adminEditSettings(
    appSpace: string,
    parameters: {},
    body: Settings_Edit,
  ): Promise<Settings> {
    let path = '/admin/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Settings segment (set default)
   * @method
   * @name Api#adminDeleteSettingsSegment
   */
  adminDeleteSettingsSegment(
    appSpace: string,
    segment: string,
    parameters: {} = {},
  ): Promise<Settings> {
    let path = '/admin/{appSpace}/settings/{segment}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{segment}', segment.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Settings for Given Contentspace
   * @method
   * @name Api#publicGetSettings
   */
  publicGetSettings(appSpace: string, parameters: {} = {}): Promise<Settings> {
    let path = '/public/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Settings for Given Contentspace
   * @method
   * @name Api#publicGetSettingsSegment
   */
  publicGetSettingsSegment(
    appSpace: string,
    segment: string,
    parameters: {} = {},
  ): Promise<Settings_Edit> {
    let path = '/public/{appSpace}/settings/{segment}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{segment}', segment.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Forms
   * @method
   * @name Api#adminGetForms
   */
  adminGetForms(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    forms?: Array<FormHeader>;

    total: number;

    limit: number;

    offset: number;
  }> {
    let path = '/admin/{appSpace}/forms';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form
   * @method
   * @name Api#adminGetForm
   */
  adminGetForm(
    appSpace: string,
    formId: string,
    parameters: {} = {},
  ): Promise<Form> {
    let path = '/admin/{appSpace}/forms/{formId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance, or create one for user
   * @method
   * @name Api#adminGetFormInstances
   */
  adminGetFormInstances(
    appSpace: string,
    formId: string,
    parameters: {
      limit?: number;
      offset?: number;
      status?: Array<string>;
    } = {},
  ): Promise<{
    instances?: Array<FormInstanceHeader>;

    total: number;

    limit: number;

    offset: number;
  }> {
    let path = '/admin/{appSpace}/forms/{formId}/instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instances
   * @method
   * @name Api#adminGetControllerFormInstances
   */
  adminGetControllerFormInstances(
    appSpace: string,
    formId: string,
    parameters: {
      limit?: number;
      offset?: number;
      status?: Array<string>;
    } = {},
  ): Promise<{
    instances?: Array<FormInstanceHeader>;

    total: number;

    limit: number;

    offset: number;
  }> {
    let path = '/admin/{appSpace}/forms/{formId}/controller-instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance
   * @method
   * @name Api#adminGetFormInstance
   */
  adminGetFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<FormInstanceAdmin> {
    let path = '/admin/{appSpace}/forms/{formId}/instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance
   * @method
   * @name Api#adminUpdateFormInstance
   */
  adminUpdateFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {},
    body: {
      internalComment?: string;

      status?: FormInstanceStatus;

      statusComment?: string;
    },
  ): Promise<FormInstanceAdmin> {
    let path = '/admin/{appSpace}/forms/{formId}/instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance
   * @method
   * @name Api#adminGetControllerFormInstance
   */
  adminGetControllerFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<FormInstanceAdmin> {
    let path =
      '/admin/{appSpace}/forms/{formId}/controller-instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance
   * @method
   * @name Api#adminUpdateControllerFormInstance
   */
  adminUpdateControllerFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {},
    body: {
      internalComment?: string;

      status?: FormInstanceStatus;

      statusComment?: string;
    },
  ): Promise<FormInstanceAdmin> {
    let path =
      '/admin/{appSpace}/forms/{formId}/controller-instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance uploads
   * @method
   * @name Api#adminGetFormInstanceUploads
   */
  adminGetFormInstanceUploads(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<{
    uploads?: Array<FormInstanceUpload>;
  }> {
    let path =
      '/admin/{appSpace}/forms/{formId}/instances/{instanceId}/uploads';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance upload
   * @method
   * @name Api#adminGetFormInstanceUploadDownloader
   */
  adminGetFormInstanceUploadDownloader(
    appSpace: string,
    formId: string,
    instanceId: string,
    uploadId: string,
    parameters: {} = {},
  ): Promise<Downloader> {
    let path =
      '/admin/{appSpace}/forms/{formId}/instances/{instanceId}/uploads/{uploadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{uploadId}', uploadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance upload
   * @method
   * @name Api#adminGetControllerFormInstanceUploadDownloader
   */
  adminGetControllerFormInstanceUploadDownloader(
    appSpace: string,
    formId: string,
    instanceId: string,
    uploadId: string,
    parameters: {} = {},
  ): Promise<Downloader> {
    let path =
      '/admin/{appSpace}/forms/{formId}/controller-instances/{instanceId}/uploads/{uploadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{uploadId}', uploadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Forms
   * @method
   * @name Api#publicGetForms
   */
  publicGetForms(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    forms?: Array<FormHeader>;

    total: number;

    limit: number;

    offset: number;
  }> {
    let path = '/public/{appSpace}/forms';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form
   * @method
   * @name Api#publicGetForm
   */
  publicGetForm(
    appSpace: string,
    formId: string,
    parameters: {} = {},
  ): Promise<Form> {
    let path = '/public/{appSpace}/forms/{formId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance, or create one for user
   * @method
   * @name Api#getOrCreateFormInstance
   */
  getOrCreateFormInstance(
    appSpace: string,
    formId: string,
    parameters: {} = {},
  ): Promise<FormInstance> {
    let path = '/public/{appSpace}/forms/{formId}/instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance
   * @method
   * @name Api#getFormInstance
   */
  getFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<FormInstance> {
    let path = '/public/{appSpace}/forms/{formId}/instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * update Form instance values. Nie je potrebne posielat vsetky hodnoty, staci zmenene. Updatne sa podla fieldId, alebo sa prida do pola hodnot.
   * @method
   * @name Api#updateFormInstance
   */
  updateFormInstance(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {},
    body: {
      values: Array<FormFieldValue>;
    },
  ): Promise<FormInstance> {
    let path = '/public/{appSpace}/forms/{formId}/instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance uploads
   * @method
   * @name Api#getFormInstanceUploads
   */
  getFormInstanceUploads(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<{
    uploads?: Array<FormInstanceUpload>;
  }> {
    let path =
      '/public/{appSpace}/forms/{formId}/instances/{instanceId}/uploads';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Upload new document to this form instance
   * @method
   * @name Api#createFormInstanceUploadAction
   */
  createFormInstanceUploadAction(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {},
    form: {
      upload: File;
    },
  ): Promise<FormInstanceUpload> {
    let path =
      '/public/{appSpace}/forms/{formId}/instances/{instanceId}/uploads';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }

  /**
   * Get Form instance upload
   * @method
   * @name Api#getFormInstanceUploadDownloader
   */
  getFormInstanceUploadDownloader(
    appSpace: string,
    formId: string,
    instanceId: string,
    uploadId: string,
    parameters: {} = {},
  ): Promise<Downloader> {
    let path =
      '/public/{appSpace}/forms/{formId}/instances/{instanceId}/uploads/{uploadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{uploadId}', uploadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Form instance upload
   * @method
   * @name Api#deleteFormInstanceUploadAction
   */
  deleteFormInstanceUploadAction(
    appSpace: string,
    formId: string,
    instanceId: string,
    uploadId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/public/{appSpace}/forms/{formId}/instances/{instanceId}/uploads/{uploadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{uploadId}', uploadId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Finish Form instance
   * @method
   * @name Api#finishFormInstanceAction
   */
  finishFormInstanceAction(
    appSpace: string,
    formId: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<FormInstance> {
    let path =
      '/public/{appSpace}/forms/{formId}/instances/{instanceId}/finish';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{formId}', formId.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get codelist
   * @method
   * @name Api#getFormInstanceStatusCodelist
   */
  getFormInstanceStatusCodelist(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    codelist: Array<CodelistItem>;
  }> {
    let path = '/public/{appSpace}/codelists/form-instance-status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Events
   * @method
   * @name Api#getUser
   */
  getUser(parameters: {} = {}): Promise<{}> {
    let path = '/user';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name Api#getDownloaderFile
   */
  getDownloaderFile(downloadId: string, parameters: {} = {}): Promise<string> {
    let path = '/downloader/{downloadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/pdf');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{downloadId}', downloadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
