import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../utilities';

interface OwnProps {
  statusOptions: Array<{ label: string; value: string }>;
  status?: { label: string; value: string };
}

type Props = OwnProps;

const InstanceStatusChangeForm: React.FC<Props> = ({
  statusOptions,
  status,
}) => (
  <Row>
    <Col xs={12}>
      <Field
        component={FormField}
        name="status"
        label={__('Stav')}
        type="theselect"
        options={statusOptions}
        validate={[required]}
        required
      />
    </Col>
    {status && (status.value === 'STORNO' || status.value === 'REJECTED') && (
      <Col xs={12}>
        <Field
          component={FormField}
          name="reason"
          parse={(i: string) => i.trimStart()}
          label={__('Dôvod')}
          type="textarea"
          validate={[required]}
          required
        />
      </Col>
    )}
  </Row>
);

export default compose<any, any, any>(
  reduxForm({}),
  connect(),
)(InstanceStatusChangeForm);
