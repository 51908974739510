import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { FormField as IFormSegmentElement } from '../../../api';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
}

const TextInputFormElement: React.FC<OwnProps> = ({ data, name }) => (
  <Field readOnly name={name} component={FormField} label={data.label} />
);

export default TextInputFormElement;
