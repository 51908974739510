import Layout from '@sportnet/ui/lib/TheLayout';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationInfo, Appspace, User } from './library/App';
import FormDetail from './pages/Form';
import FormInstanceDetail from './pages/FormInstance';
import FormsList from './pages/Forms';
import DomainSettings from './pages/Settings/Domain';
import { __ } from './utilities';

type OwnProps = {
  appspace: string;
  activeAppspace?: Appspace | null;
  organizationName: string;
  application: ApplicationInfo | null;
  user: User | null;
};

const RoutesSwitch: React.FC<OwnProps> = (
  { appspace, activeAppspace, application, organizationName, user },
  context,
) => {
  const settings = {
    bottom: <div />,
    top: <div />,
    name: activeAppspace && activeAppspace.display_name,
    title: application ? application.name : undefined,
    logo: application ? application.logo : undefined,
    userOrganization: organizationName || '',
    userOrganizationProfile:
      activeAppspace && activeAppspace.org_profile
        ? activeAppspace.org_profile.name
        : '',
    userName: activeAppspace ? activeAppspace.display_name || '' : '',
    onLogout: context.logout,
    userPictureSrc:
      user && user.photo && user.photo.public_url
        ? user.photo.public_url
        : undefined,
  };

  return (
    <Switch>
      <Route
        path="/admin/:appspace/:path"
        render={() => (
          <Layout
            title="Smart Formuláre"
            navigation={[
              {
                label: __('Formuláre'),
                url: `/admin/${appspace}/forms`,
              },
              {
                label: __('Nastavenia'),
                subitems: [
                  {
                    label: __('Doména'),
                    url: `/admin/${appspace}/settings/domain`,
                  },
                ],
              },
            ]}
            {...settings}
          >
            <Route exact path="/admin/:appspace/forms" component={FormsList} />
            <Route
              path="/admin/:appspace/forms/:formId"
              component={FormDetail}
            />
            <Route
              exact
              path="/admin/:appSpace/forms/:formId/instances/:instanceId"
              component={FormInstanceDetail}
            />
            <Route
              exact
              path="/admin/:appspace/settings/domain"
              component={DomainSettings}
            />
          </Layout>
        )}
      />
    </Switch>
  );
};

RoutesSwitch.contextTypes = {
  logout: PropTypes.func,
};

export default RoutesSwitch;
